import React, { useState, useEffect, useRef } from 'react';

const CurseurExponentiel = ({ min, max, valeur, onChange }) => {
  const [valeurCurseur, setValeurCurseur] = useState(0);
  const [valeurInput, setValeurInput] = useState(valeur.toString());

  useEffect(() => {
    const logMin = Math.log(min);
    const logMax = Math.log(max);
    const echelle = (logMax - logMin) / 100;
    setValeurCurseur(Math.round((Math.log(valeur) - logMin) / echelle));
    setValeurInput(valeur.toString());
  }, [min, max, valeur]);

  const gererChangementCurseur = (e) => {
    const nouvelleValeurCurseur = Number(e.target.value);
    setValeurCurseur(nouvelleValeurCurseur);
    const logMin = Math.log(min);
    const logMax = Math.log(max);
    const echelle = (logMax - logMin) / 100;
    const resultat = Math.round(Math.exp(logMin + echelle * nouvelleValeurCurseur));
    onChange(resultat);
    setValeurInput(resultat.toString());
  };

  const gererChangementInput = (e) => {
    const nouvelleValeur = e.target.value;
    setValeurInput(nouvelleValeur);
    const valeurNumerique = Math.max(min, Math.min(max, Number(nouvelleValeur) || min));
    onChange(valeurNumerique);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <input
        type="range"
        min="0"
        max="100"
        value={valeurCurseur}
        onChange={gererChangementCurseur}
        style={{ flexGrow: 1, accentColor: '#0077be' }}
      />
      <input
        type="number"
        min={min}
        max={max}
        value={valeurInput}
        onChange={gererChangementInput}
        style={{ width: '100px', padding: '5px', backgroundColor: '#0077be', color: 'white', border: 'none', borderRadius: '4px' }}
      />
    </div>
  );
};

const EditableText = ({ value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onChange(tempValue);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
      onChange(tempValue);
    }
  };

  if (isEditing) {
    return (
      <input
        ref={inputRef}
        value={tempValue}
        onChange={(e) => setTempValue(e.target.value)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        style={{
          fontSize: '34px',
          backgroundColor: 'transparent',
          color: '#ffffff',
          border: 'none',
          borderBottom: '2px solid #0077be',
          outline: 'none',
          width: '35%'
        }}
      />
    );
  }

  return (
    <span 
      onClick={handleClick} 
      style={{ 
        cursor: 'pointer',
        borderBottom: '2px dashed #0077be'
      }}
    >
      {value}
    </span>
  );
};

const CalculateurROIChatbot = () => {
  const getQueryParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      visiteurs: parseInt(params.get('visiteurs')) || 7500,
      forfaitAnnuel: params.get('forfaitAnnuel') === 'true',
      pourcentageLeadsGagnes: parseInt(params.get('pourcentageLeadsGagnes')) || 25,
      valeurClientMoyenne: parseInt(params.get('valeurClientMoyenne')) || 10000,
      tauxMQLaSQL: parseInt(params.get('tauxMQLaSQL')) || 40,
      companyName: params.get('companyName') || " - ",
    };
  };

  // Initialize state using URL parameters
  const initialParams = getQueryParams();
  const [visiteurs, setVisiteurs] = useState(initialParams.visiteurs);
  const [forfaitAnnuel, setForfaitAnnuel] = useState(initialParams.forfaitAnnuel);
  const [pourcentageLeadsGagnes, setPourcentageLeadsGagnes] = useState(initialParams.pourcentageLeadsGagnes);
  const [valeurClientMoyenne, setValeurClientMoyenne] = useState(initialParams.valeurClientMoyenne);
  const [tauxMQLaSQL, setTauxMQLaSQL] = useState(initialParams.tauxMQLaSQL);
  const [companyName, setCompanyName] = useState(initialParams.companyName);
  const [roiRange, setRoiRange] = useState({ min: 0, max: 0 });
  const [revenueRange, setRevenueRange] = useState({ min: 0, max: 0 });

  // Function to update query parameters
  const updateQueryParams = () => {
    const searchParams = new URLSearchParams();
    searchParams.set('visiteurs', visiteurs.toString());
    searchParams.set('forfaitAnnuel', forfaitAnnuel.toString());
    searchParams.set('pourcentageLeadsGagnes', pourcentageLeadsGagnes.toString());
    searchParams.set('valeurClientMoyenne', valeurClientMoyenne.toString());
    searchParams.set('tauxMQLaSQL', tauxMQLaSQL.toString());
    searchParams.set('companyName', companyName);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  const tauxConversation = {
    faible: 0.5,
    eleve: 2
  };

  const tauxCapture = {
    faible: 30,
    eleve: 60
  };

  const calculerScenario = (taux) => {
    const visiteursAnnuels = visiteurs * 4; // Convert quarterly visitors to annual
    const conversations = Math.round(visiteursAnnuels * (tauxConversation[taux] / 100));
    const mql = Math.round(conversations * (tauxCapture[taux] / 100));
    const meetings = Math.round(mql * (tauxMQLaSQL / 100));
    const clientsAcquis = Math.round(meetings * (pourcentageLeadsGagnes / 100));
    const revenu = Math.round(clientsAcquis * valeurClientMoyenne);
    return { conversations, mql, meetings, clientsAcquis, revenu };
  };
  useEffect(() => {
    const params = getQueryParams();
    setVisiteurs(params.visiteurs);
    setForfaitAnnuel(params.forfaitAnnuel);
    setPourcentageLeadsGagnes(params.pourcentageLeadsGagnes);
    setValeurClientMoyenne(params.valeurClientMoyenne);
    setTauxMQLaSQL(params.tauxMQLaSQL);
    setCompanyName(params.companyName);
  }, []);

  useEffect(() => {
    updateQueryParams({
      visiteurs,
      forfaitAnnuel,
      pourcentageLeadsGagnes,
      valeurClientMoyenne,
      tauxMQLaSQL,
      companyName,
    });
  }, [visiteurs, forfaitAnnuel, pourcentageLeadsGagnes, valeurClientMoyenne, tauxMQLaSQL, companyName]);

  const scenarios = {
    conservateur: calculerScenario('faible'),
    réaliste: {
      conversations: Math.round((calculerScenario('faible').conversations + calculerScenario('eleve').conversations) / 2),
      mql: Math.round((calculerScenario('faible').mql + calculerScenario('eleve').mql) / 2),
      meetings: Math.round((calculerScenario('faible').meetings + calculerScenario('eleve').meetings) / 2),
      clientsAcquis: Math.round((calculerScenario('faible').clientsAcquis + calculerScenario('eleve').clientsAcquis) / 2),
      revenu: Math.round((calculerScenario('faible').revenu + calculerScenario('eleve').revenu) / 2)
    },
    optimiste: calculerScenario('eleve')
  };

  const coutMensuel = forfaitAnnuel ? 295 : 395;
  const coutTotal = coutMensuel * 12; // Annual cost

  const calculerROI = (revenu) => {
    const roi = ((revenu - coutTotal) / coutTotal) * 100;
    return roi.toFixed(0);
  };

  useEffect(() => {
    const conservateurROI = parseFloat(calculerROI(scenarios.conservateur.revenu));
    const optimisteROI = parseFloat(calculerROI(scenarios.optimiste.revenu));
    setRevenueRange({
      min: Math.min(scenarios.conservateur.revenu, scenarios.optimiste.revenu),
      max: Math.max(scenarios.conservateur.revenu, scenarios.optimiste.revenu)
    });
    setRoiRange({ 
      min: Math.min(conservateurROI, optimisteROI), 
      max: Math.max(conservateurROI, optimisteROI) 
    });
  }, [visiteurs, forfaitAnnuel, pourcentageLeadsGagnes, valeurClientMoyenne, tauxMQLaSQL]);


  const ouvrirCalendly = () => {
    window.open('https://calendly.com/quebgpt/qualification-capture', '_blank', 'noopener,noreferrer');
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD', maximumFractionDigits: 0 }).format(value);
  };
  const [copySuccess, setCopySuccess] = useState('');

  useEffect(() => {
    updateQueryParams();
  }, [visiteurs, forfaitAnnuel, pourcentageLeadsGagnes, valeurClientMoyenne, tauxMQLaSQL, companyName]);

  const copyToClipboard = () => {
    const currentUrl = window.location.href;

    if (navigator.clipboard && navigator.clipboard.writeText) {
      // Use Clipboard API if available
      navigator.clipboard.writeText(currentUrl)
        .then(() => {
          setCopySuccess('URL copiée !');
          setTimeout(() => setCopySuccess(''), 3000);
        })
        .catch(err => {
          console.error('Erreur lors de la copie: ', err);
          setCopySuccess('Erreur lors de la copie');
        });
    } else {
      // Fallback to textarea method
      const textArea = document.createElement("textarea");
      textArea.value = currentUrl;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setCopySuccess('URL copiée !');
        } else {
          setCopySuccess('Copie manuelle nécessaire');
        }
      } catch (err) {
        console.error('Erreur lors de la copie: ', err);
        setCopySuccess('Erreur lors de la copie');
      }

      document.body.removeChild(textArea);
    }

    setTimeout(() => setCopySuccess(''), 3000);
  };
  return (
    
    <div style={{ maxWidth: '800px', margin: 'auto', padding: '40px', fontFamily: 'Arial, sans-serif', backgroundColor: '#002f4e', color: 'white', borderRadius: '8px', position: 'relative'  }}>
      <img src="https://storage.googleapis.com/capture-assets/pocast_.png" 
        alt="Logo" 
        style={{
          position: 'absolute',
          top: '5px',
          right: '20px',
          width: '150px', // Adjust this value as needed
          height: 'auto'
        }}></img>
      <h1 style={{ fontSize: '42px', marginBottom: '30px', marginTop: '100px', color: '#ffffff', textAlign:"center" }}>ROI annuel avec Capture</h1>
      <h1 style={{ fontSize: '34px', marginBottom: '30px', color: '#ffffff' }}>
        Entreprise: <EditableText value={companyName} onChange={setCompanyName} />
      </h1>      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
        <p style={{ fontSize: '28px', color: '#ffffff', margin: 0 }}>
          Retour sur investissement:
        </p>
        <p style={{ fontSize: '28px', color: '#ffffff', margin: 0 }}>
          <strong>{roiRange.min} %</strong> à <strong>{roiRange.max} %</strong>
        </p>
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
        <p style={{ fontSize: '28px', color: '#ffffff', margin: 0 }}>
          Revenu additionnel:
        </p>
        <p style={{ fontSize: '28px', color: '#ffffff', margin: 0 }}>
          <strong>{formatCurrency(revenueRange.min)}</strong> à <strong>{formatCurrency(revenueRange.max)}</strong>
        </p>
      </div>
      
      <hr></hr>
      <br></br>
      <div style={{ marginBottom: '30px' }}>
        <label htmlFor="visiteurs" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
          Nombre de visiteurs (trimestre)
        </label>
        <CurseurExponentiel
          min={1500}
          max={1500000}
          valeur={visiteurs}
          onChange={setVisiteurs}
        />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <label htmlFor="tauxMQLaSQL" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
          Taux de conversion leads à meetings (%)
        </label>
        <input
          type="number"
          min="0"
          max="100"
          value={tauxMQLaSQL}
          onChange={(e) => setTauxMQLaSQL(Number(e.target.value))}
          style={{ width: '100px', padding: '5px', backgroundColor: '#0077be', color: 'white', border: 'none', borderRadius: '4px' }}
        />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <label htmlFor="pourcentageLeadsGagnes" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
          Pourcentage de leads gagnés (%)
        </label>
        <input
          type="number"
          min="0"
          max="100"
          value={pourcentageLeadsGagnes}
          onChange={(e) => setPourcentageLeadsGagnes(Number(e.target.value))}
          style={{ width: '100px', padding: '5px', backgroundColor: '#0077be', color: 'white', border: 'none', borderRadius: '4px' }}
        />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <label htmlFor="valeurClientMoyenne" style={{ display: 'block', marginBottom: '10px', fontSize: '18px' }}>
          Valeur Moyenne d'un Client ($)
        </label>
        <input
          type="number"
          min="0"
          value={valeurClientMoyenne}
          onChange={(e) => setValeurClientMoyenne(Number(e.target.value))}
          style={{ width: '100px', padding: '5px', backgroundColor: '#0077be', color: 'white', border: 'none', borderRadius: '4px' }}
        />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <div style={{ marginBottom: '10px', fontSize: '18px' }}>Type de forfait :</div>
        <label style={{ display: 'inline-flex', alignItems: 'center', marginRight: '20px', cursor: 'pointer' }}>
          <input
            type="radio"
            checked={!forfaitAnnuel}
            onChange={() => setForfaitAnnuel(false)}
            style={{ marginRight: '10px', accentColor: '#0077be' }}
          />
          Mensuel (395$ / mois)
        </label>
        <label style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
          <input
            type="radio"
            checked={forfaitAnnuel}
            onChange={() => setForfaitAnnuel(true)}
            style={{ marginRight: '10px', accentColor: '#0077be' }}
          />
          Annuel (295$ / mois)
        </label>
      </div>

      <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 10px' }}>
        <thead>
          <tr>
            {['Scénario', 'Conversations', 'Leads', 'Meetings', 'Clients Acquis', 'Revenu ($)', 'ROI (%)'].map((header) => (
              <th key={header} style={{ 
                padding: '10px', 
                textAlign: 'center', 
                borderBottom: '2px solid #0077be'
              }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(scenarios).map(([scenario, { conversations, mql, meetings, clientsAcquis, revenu }]) => (
            <tr key={scenario} style={{ backgroundColor: 'rgba(0, 119, 190, 0.1)' }}>
              <td style={{ padding: '10px', textAlign: 'center', textTransform: 'capitalize' }}>{scenario}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{conversations.toLocaleString('fr-FR')}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{mql.toLocaleString('fr-FR')}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{meetings.toLocaleString('fr-FR')}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{clientsAcquis.toLocaleString('fr-FR')}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>${revenu.toLocaleString('fr-FR')}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{calculerROI(revenu)}%</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: '30px', fontSize: '14px', color: '#a0a0a0' }}>
        <p>Taux de conversation : Faible = {tauxConversation.faible}%, Élevé = {tauxConversation.eleve}%</p>
        <p>Taux de capture : Faible = {tauxCapture.faible}%, Élevé = {tauxCapture.eleve}%</p>
      </div>
      
      <div style={{ marginTop: '30px', display: 'flex', alignItems: 'center', gap: '10px' }}>
        <button 
          onClick={copyToClipboard}
          style={{ 
            padding: '15px 30px', 
            fontSize: '18px', 
            backgroundColor: '#0077be', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <circle cx="18" cy="5" r="3"></circle>
            <circle cx="6" cy="12" r="3"></circle>
            <circle cx="18" cy="19" r="3"></circle>
            <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
            <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
          </svg>
          Partager le calculateur
        </button>
        {copySuccess && <span style={{ color: '#ffffff' }}>{copySuccess}</span>}
      </div>
      {/* <button 
        onClick={ouvrirCalendly}
        style={{ 
          marginTop: '30px', 
          padding: '15px 30px', 
          fontSize: '18px', 
          backgroundColor: '#0077be', 
          color: 'white', 
          border: 'none', 
          borderRadius: '5px', 
          cursor: 'pointer' 
        }}
      >
        PLANIFIEZ UNE RENCONTRE
      </button> */}
    </div>
  );
};

function App() {
  return <CalculateurROIChatbot />;
}

export default App;